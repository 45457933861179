<header>
  <span>Scrum Poker</span>
</header>

<section class="main-container">
  <router-outlet></router-outlet>
</section>

<footer>
  <span>Erstellt von Ben B. und Joe H.</span>
</footer>

