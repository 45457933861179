<div class="login-container">

  <div class="wrapper">

    <h1>Willkommen!</h1>

    <p>Bitte gib deinen Namen und deine Rolle im Team an:</p>

    <form [formGroup]="formGroup">
      <input class="input-user-name" type="text" placeholder="Name" name="name" formControlName="name">
      <app-groups (groupSelected)="onGroupSelected($event)" class="input-group-select"></app-groups>
    </form>
    <button class="submit-btn" (click)="submit()" [disabled]="(formGroup.value.name === '') || (selectedGroup === undefined)">
      Zum Pokern
    </button>
  </div>
</div>

